import React, { useEffect, useRef} from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";
// import { MotionPathHelper } from "gsap/MotionPathHelper";

import pageNo3 from "../../image/page_no3.png";

import { List,ListItem,ListIcon,Heading,Text ,Box, SimpleGrid,Image} from '@chakra-ui/react';
import { MdSettings } from 'react-icons/md';

// section 3 
import worddpress from "../../image/wordpress.png";
import react from "../../image/react.png";
import html from "../../image/html.png";
import uiux from "../../image/uiux.png";




gsap.registerPlugin(ScrollTrigger);
// gsap.registerPlugin(MotionPathHelper);
gsap.registerPlugin(MotionPathPlugin);

export default function Section3(){


    const section3Ref = useRef(null);
    const section3headingRef = useRef(null);
    const descriptionRef = useRef(null);

    const dashedRoundRef = useRef(null);
    const dashedRound2Ref = useRef(null);
    const dashedRound3Ref = useRef(null);
    const dashedRound4Ref = useRef(null);

    const skillBox1Ref = useRef(null);
    const skillBoxSub1Ref =useRef(null);
    const imageBox1Ref = useRef(null);

    const skillBox2Ref = useRef(null);
    const skillBoxSub2Ref =useRef(null);
    const imageBox2Ref = useRef(null);

    const skillBox3Ref = useRef(null);
    const skillBoxSub3Ref =useRef(null);
    const imageBox3Ref = useRef(null);

    const skillBox4Ref = useRef(null);
    const skillBoxSub4Ref =useRef(null);
    const imageBox4Ref = useRef(null);

   useEffect(()=>{
        // Rotate animation
        gsap.to(dashedRoundRef.current ,{ rotation: 360,  duration:5, ease: "linear", repeat: -1 } );
        gsap.to(dashedRound2Ref.current ,{ rotation: 360,  duration:5, ease: "linear", repeat: -1 } );
        gsap.to(dashedRound3Ref.current ,{ rotation: 360,  duration:5, ease: "linear", repeat: -1 } );
        gsap.to(dashedRound4Ref.current ,{ rotation: 360,  duration:5, ease: "linear", repeat: -1 } );

        const tl = gsap.timeline();
        tl.to(section3headingRef.current , {opacity:1 , duration:10 , ease:"power1.inOut"})
        .to(descriptionRef.current , {opacity:1 , duration:10 , ease:"power1.inOut"})

        let scrollTriggerInstance1 =  ScrollTrigger.create({
            animation: tl, // Assuming you have an animation timeline assigned to highlightTl
            trigger: section3Ref.current,
            start: 'center 60%',
            end: '+=300 bottom',
            scrub: 1,
            markers: false,
        });

         // Return a cleanup function
        return () => {
          if (scrollTriggerInstance1) {
            scrollTriggerInstance1.kill(); // Clean up the ScrollTrigger instance
          }
        }

   },[1]);



// ---------------------------------------------------------------------------


   useEffect(()=>{
        gsap.fromTo(skillBoxSub1Ref.current, 
          { y: 300 }, 
          { 
            y: 0, 
            duration: 10, 
            ease: "power1.inOut",
            scrollTrigger: {
              trigger: skillBox1Ref.current,
              start: "bottom 30%", // when the top of the element hits the bottom of the viewport
              end: "bottom top", // when the top of the element hits the top of the viewport
              scrub: true, // smooth scrubbing,
              markers:false
            }
          }
        )
   },[1]);

   useEffect(()=>{
    gsap.fromTo(skillBoxSub2Ref.current, 
      { y: 300 }, 
      { 
        y: 0, 
        duration: 10, 
        ease: "power1.inOut",
        scrollTrigger: {
          trigger: skillBox2Ref.current,
          start: "bottom 30%", // when the top of the element hits the bottom of the viewport
          end: "bottom top", // when the top of the element hits the top of the viewport
          scrub: true, // smooth scrubbing,
          markers:false
        }
      }
    )
},[1]);


useEffect(()=>{
  gsap.fromTo(skillBoxSub3Ref.current, 
    { y: 300 }, 
    { 
      y: 0, 
      duration: 10, 
      ease: "power1.inOut",
      scrollTrigger: {
        trigger: skillBox3Ref.current,
        start: "bottom 30%", // when the top of the element hits the bottom of the viewport
        end: "bottom top", // when the top of the element hits the top of the viewport
        scrub: true, // smooth scrubbing,
        markers:false
      }
    }
  )
},[1]);

useEffect(()=>{
  gsap.fromTo(skillBoxSub4Ref.current, 
    { y: 300 }, 
    { 
      y: 0, 
      duration: 10, 
      ease: "power1.inOut",
      scrollTrigger: {
        trigger: skillBox4Ref.current,
        start: "bottom 30%", // when the top of the element hits the bottom of the viewport
        end: "bottom top", // when the top of the element hits the top of the viewport
        scrub: true, // smooth scrubbing,
        markers:false
      }
    }
  )
},[1]);

// ---------------------------------------------------------------------------

   useEffect(()=>{
    gsap.to(imageBox1Ref.current, {
      scrollTrigger: {
        trigger: imageBox1Ref.current,
        start: "350% top",
        end: "+=500 top",
        scrub: 1,
        markers:false
      },
      motionPath: {
        path: [
          { y: 50, x: -400 },
          { y: 200, x: 0 },
          { y: 300, x: 100 },
          { y: 400, x: 400 }
        ],
        curviness: 1.25,
        autoRotate: false,
      },
      duration: 100,
    });
   },[])


   useEffect(()=>{
    gsap.to(imageBox2Ref.current, {
      scrollTrigger: {
        trigger: imageBox2Ref.current,
        start: "350% top",
        end: "+=500 top",
        scrub: 1,
        markers:false
      },
      motionPath: {
        path: [
          { y: 50, x: -400 },
          { y: 200, x: 0 },
          { y: 300, x: 100 },
          { y: 400, x: 400 }
        ],
        curviness: 1.25,
        autoRotate: false,
      },
      duration: 100,
    });
   },[]);


   useEffect(()=>{
    gsap.to(imageBox3Ref.current, {
      scrollTrigger: {
        trigger: imageBox3Ref.current,
        start: "350% top",
        end: "+=500 top",
        scrub: 1,
        markers:false
      },
      motionPath: {
        path: [
          { y: 50, x: -400 },
          { y: 200, x: 0 },
          { y: 300, x: 100 },
          { y: 400, x: 400 }
        ],
        curviness: 1.25,
        autoRotate: false,
      },
      duration: 100,
    });
   },[]);


   useEffect(()=>{
    gsap.to(imageBox4Ref.current, {
      scrollTrigger: {
        trigger: imageBox4Ref.current,
        start: "350% top",
        end: "+=500 top",
        scrub: 1,
        markers:false
      },
      motionPath: {
        path: [
          { y: 50, x: -400 },
          { y: 200, x: 0 },
          { y: 300, x: 100 },
          { y: 400, x: 400 }
        ],
        curviness: 1.25,
        autoRotate: false,
      },
      duration: 100,
    });
   },[]);

    return(
        <section  className="section3" ref={section3Ref}>
          <div className="wrapper" >
            <Heading ref={section3headingRef} as='h2'size={{ base: '2xl', md: '3xl' }} opacity={0}  textAlign={"center"} marginTop={{base:'50px',md:"100px"}} fontFamily="EB Garamond" color={"#6b6b6b"} fontWeight={"400"}>
              Chapter 2 - Gaining Skills
            </Heading>

                

            <Text
              ref={descriptionRef}
                mt={10}
                width={{base:'90%',md:'50%'}}
                textAlign={'center'}
                fontSize={"20px"}
                fontWeight={"400"}
                fontFamily="EB Garamond"
                color={"black"}
                opacity={0}
              >
                Every line of code learned, every bug fixed—this chapter captures the evolution of my skills, from HTML and CSS to mastering React.js and PHP.
            </Text>

            <Box width={'85%'} height={"auto"} ref={skillBox1Ref} marginTop={"100px"} overflow={"hidden"}>
              <SimpleGrid columns={{ base: 1, md: 2 }  }  spacing={4}>
                <Box display={"flex"} flexDirection={"column"} ref={skillBoxSub1Ref}>
                    <Heading as='h2'size={{ base: '2xl', md: '2xl' }}   fontFamily="EB Garamond" color={"black"} fontWeight={"400"}>
                      Wordpress
                    </Heading>

                    <Text
                        mt={10}
                        fontSize={"20px"}
                        fontWeight={"400"}
                        fontFamily="EB Garamond"
                        color={"black"}
                      >
                        I specializing in creating business website,woocommerce website and online store for ecommerce business. 
                        <List spacing={2} mt={5}>
                          <ListItem>
                            <ListIcon as={MdSettings} color='black.500' />
                            Payment gateway integration
                          </ListItem>
                          <ListItem>
                            <ListIcon as={MdSettings} color='black.500' />
                            Live chat, Discount coupon popup
                          </ListItem>
                          <ListItem>
                            <ListIcon as={MdSettings} color='black.500' />
                            Setup woocommerce/Setup free SSL
                          </ListItem>
                          <ListItem>
                            <ListIcon as={MdSettings} color='black.500' />
                            Multi vendor e-commerce/Social media integration
                          </ListItem>
                        </List>
                    </Text>

                   
                </Box>

                <Box display={{ base: 'none', md: "flex" }} flexDirection={"column"} justifyContent={"flex-start"} alignItems={"end"} overflow={"hidden"}>
                  <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"} ref={imageBox1Ref} position={"absolute"}  transform={"translate(200px ,-200px)"}>
                    <Box  border={"#3E331D 6px dashed"} borderRadius={"50%"} width={'180px'} height={'180px'} ref={dashedRoundRef} ></Box>
                    <Image src={worddpress} alt='wordpress' width={'150px'} height={'150px'} position={"absolute"}/>
                  </Box>
                </Box>

              </SimpleGrid>
            </Box>



            <Box width={'85%'} height={"auto"} ref={skillBox2Ref} marginTop={"100px"} overflow={"hidden"}>
              <SimpleGrid columns={{ base: 1, md: 2 }  }  spacing={4}>
                <Box display={"flex"} flexDirection={"column"} ref={skillBoxSub2Ref}>
                <Heading as='h2'size={{ base: '2xl', md: '2xl' }}   fontFamily="EB Garamond" color={"black"} fontWeight={"400"}>
                      HTML , CSS
                    </Heading>

                 <Text
                        mt={10}
                        fontSize={"20px"}
                        fontWeight={"400"}
                        fontFamily="EB Garamond"
                        color={"black"}
                      >
                        I am develop Front-end with HTML,CSS with diffrent library like Bootsrap ,Tailwindcss 
                        <List spacing={2} mt={5}>
                          <ListItem>
                            <ListIcon as={MdSettings} color='black.500' />
                              Responsive web design
                            </ListItem>
                          <ListItem>
                            <ListIcon as={MdSettings} color='black.500' />
                              Figma to HTML,CSS
                            </ListItem>
                          <ListItem>
                            <ListIcon as={MdSettings} color='black.500' />
                              Material Design with Bootstrap
                            </ListItem>
                        </List>
                    </Text>
                </Box>

                <Box display={{ base: 'none', md: "flex" }} flexDirection={"column"} justifyContent={"flex-start"} alignItems={"end"} overflow={"hidden"}>

                  <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"} ref={imageBox2Ref} position={"absolute"}  transform={"translate(200px ,-200px)"}>
                    <Box  border={"#3E331D 6px dashed"} borderRadius={"50%"} width={'180px'} height={'180px'} ref={dashedRound2Ref} ></Box>
                    <Image src={html} alt='wordpress' width={'120px'} height={'120px'} position={"absolute"}/>
                  </Box>

                </Box>
              </SimpleGrid>
            </Box>


            <Box width={'85%'} height={"auto"} ref={skillBox3Ref} marginTop={"100px"} overflow={"hidden"}>
              <SimpleGrid columns={{ base: 1, md: 2 }  }  spacing={4}>
                <Box display={"flex"} flexDirection={"column"} ref={skillBoxSub3Ref}>
                <Heading as='h2'size={{ base: '2xl', md: '2xl' }}   fontFamily="EB Garamond" color={"black"} fontWeight={"400"}>
                     Full Stack
                    </Heading>

                 <Text
                        mt={10}
                        fontSize={"20px"}
                        fontWeight={"400"}
                        fontFamily="EB Garamond"
                        color={"black"}
                      >
                        Develop a responsive website featuring a robust front-end and back-end architecture.
                        <List spacing={2} mt={5}>
                          <ListItem>
                            <ListIcon as={MdSettings} color='black.500' />
                            Build website/web app with React js and Laravel
                            </ListItem>
                          <ListItem>
                            <ListIcon as={MdSettings} color='black.500' />
                              Animation on scroll
                            </ListItem>
                          <ListItem>
                            <ListIcon as={MdSettings} color='black.500' />
                              Fully responsive design(Mobile,Tablet,Desktop)
                            </ListItem>
                        </List>
                    </Text>
                </Box>

                <Box display={{ base: 'none', md: "flex" }} flexDirection={"column"} justifyContent={"flex-start"} alignItems={"end"} overflow={"hidden"}>

                  <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"} ref={imageBox3Ref} position={"absolute"}  transform={"translate(200px ,-200px)"}>
                    <Box  border={"#3E331D 6px dashed"} borderRadius={"50%"} width={'180px'} height={'180px'} ref={dashedRound3Ref} ></Box>
                    <Image src={react} alt='wordpress' width={'120px'} height={'120px'} position={"absolute"}/>
                  </Box>

                </Box>
              </SimpleGrid>
            </Box>


            <Box width={'85%'} height={"auto"} ref={skillBox4Ref} marginTop={"100px"} overflow={"hidden"}>
              <SimpleGrid columns={{ base: 1, md: 2 }  }  spacing={4}>
                <Box display={"flex"} flexDirection={"column"} ref={skillBoxSub4Ref}>
                <Heading as='h2'size={{ base: '2xl', md: '2xl' }}   fontFamily="EB Garamond" color={"black"} fontWeight={"400"}>
                      UI & UX
                    </Heading>

                 <Text
                        mt={10}
                        fontSize={"20px"}
                        fontWeight={"400"}
                        fontFamily="EB Garamond"
                        color={"black"}
                      >
                        Craft your website with an innovative and pristine blueprint, infused with artistic flair and modern elegance.
                        <List spacing={2} mt={5}>
                          <ListItem>
                            <ListIcon as={MdSettings} color='black.500' />
                              Creative Design
                            </ListItem>
                          <ListItem>
                            <ListIcon as={MdSettings} color='black.500' />
                              Clean & Modern
                            </ListItem>
                          <ListItem>
                            <ListIcon as={MdSettings} color='black.500' />
                              Pixel perfect Design
                            </ListItem>
                        </List>
                    </Text>
                </Box>

                <Box display={{ base: 'none', md: "flex" }} flexDirection={"column"} justifyContent={"flex-start"} alignItems={"end"} overflow={"hidden"}>

                  <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"} ref={imageBox4Ref} position={"absolute"}  transform={"translate(200px ,-200px)"}>
                    <Box  border={"#3E331D 6px dashed"} borderRadius={"50%"} width={'180px'} height={'180px'} ref={dashedRound4Ref} ></Box>
                    <Image src={uiux} alt='wordpress' width={'120px'} height={'120px'} position={"absolute"}/>
                  </Box>

                </Box>
              </SimpleGrid>
            </Box>
            

            
          </div>

          <Image src={pageNo3} alt='page no' marginTop={"50px"} className="pageNo"/>
      </section>
    );
}
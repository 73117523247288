import React, { useEffect, useRef} from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Heading,Text ,Box,Image} from '@chakra-ui/react';
import pageNo4 from "../../image/page_no4.png";

// projects
import project1 from "../../image/project1.png";
import project2 from "../../image/project2.png";
import project3 from "../../image/project3.png";

import project4 from "../../image/project4.png";
import project5 from "../../image/project5.png";
import project6 from "../../image/project6.png";

import project7 from "../../image/project7.png";
import project8 from "../../image/project8.png";
import project9 from "../../image/project9.png";


// Register ScrollTrigger
gsap.registerPlugin(ScrollTrigger);

export default function Section4 (prop){

    const section4Ref = useRef(null);
    const sliderRef = useRef(null);
    


    useEffect(()=>{

        const sectionOneAniTl = gsap.timeline();

        sectionOneAniTl.to(sliderRef.current ,{x:-(sliderRef.current.scrollWidth - sliderRef.current.clientWidth) , duration:1} );
    
          let scrollTriggerInstance = ScrollTrigger.create({
            animation:sectionOneAniTl,
            trigger: section4Ref.current,
            start: '5px top',
            endTrigger:section4Ref.current,
            end: '+=2000 top',
            pin: section4Ref.current,
            pinnedContainer:section4Ref.current,
            invalidateOnRefresh: true,
            pinSpacing: true,
            scrub: 1,
            markers: false,
            // onUpdate: (self) => {
            //   const maxScrollLeft = sliderRef.current.scrollWidth - sliderRef.current.clientWidth;
            //   const scrollLeft = self.progress * maxScrollLeft;
            //   sliderRef.current.scrollTo({ left: scrollLeft, behavior: 'smooth' });
            // }
          });
    
           // Return a cleanup function
        return () => {
          if (scrollTriggerInstance) {
            scrollTriggerInstance.kill(); // Clean up the ScrollTrigger instance
          }
        }
       
    },[1]);


    return(
        <section ref={section4Ref} className="section4">
        <div className="wrapper">

          <Heading  as='h2' size={{ base: '2xl', md: '3xl' }} textAlign={"center"} marginTop={{base:'50px',md:"00px"}} fontFamily="EB Garamond" color={"#6b6b6b"} fontWeight={"400"}>
            Chapter 3 - Signature Projects
          </Heading>


                <Box 
                    width="100%" 
                    height="65vh" 
                    marginTop="50px" 
                    display="flex" 
                    flexDirection="row" 
                    justifyContent="center" 
                    alignItems="center"
                  >
                    <Box 
                      width="70%" 
                      height="100%" 
                      display="flex" 
                      flexDirection="row" 
                      overflowX="auto"
                      whiteSpace="nowrap"
                      position="relative"
                     className="horizontal-slider"
                    >

                      <Box width={'100%'} height={"auto"}  ref={sliderRef}>
                       <Box 
                          display="inline-block" 
                          marginRight="20px" 
                          width="300px" 
                          height="100%" 
                          borderRadius="15px" 
                          border="#F7C873 5px solid"
                          flexShrink={0}
                        >
                         
                          <Image src={project1} boxSize='100%' alt='Dan Abramov' />
                       
                        </Box>


                        <Box 
                          display="inline-block" 
                          marginRight="20px" 
                          width="300px" 
                          height="100%" 
                          borderRadius="15px" 
                          border="#F7C873 5px solid"
                          flexShrink={0}
                        >
                         
                          <Image src={project2} boxSize='100%' alt='Dan Abramov' />
                       
                        </Box>


                        <Box 
                          display="inline-block" 
                          marginRight="20px" 
                          width="300px" 
                          height="100%" 
                          borderRadius="15px" 
                          border="#F7C873 5px solid"
                          flexShrink={0}
                        >
                         
                          <Image src={project3} boxSize='100%' alt='Dan Abramov' />
                       
                        </Box>



                        <Box 
                          display="inline-block" 
                          marginRight="20px" 
                          width="300px" 
                          height="100%" 
                          borderRadius="15px" 
                          border="#F7C873 5px solid"
                          flexShrink={0}
                        >
                         
                          <Image src={project4} boxSize='100%' alt='Dan Abramov' />
                       
                        </Box>


                        <Box 
                          display="inline-block" 
                          marginRight="20px" 
                          width="300px" 
                          height="100%" 
                          borderRadius="15px" 
                          border="#F7C873 5px solid"
                          flexShrink={0}
                        >
                         
                          <Image src={project5} boxSize='100%' alt='Dan Abramov' />
                       
                        </Box>


                        <Box 
                          display="inline-block" 
                          marginRight="20px" 
                          width="300px" 
                          height="100%" 
                          borderRadius="15px" 
                          border="#F7C873 5px solid"
                          flexShrink={0}
                        >
                         
                          <Image src={project6} boxSize='100%' alt='Dan Abramov' />
                       
                        </Box>


                        <Box 
                          display="inline-block" 
                          marginRight="20px" 
                          width="300px" 
                          height="100%" 
                          borderRadius="15px" 
                          border="#F7C873 5px solid"
                          flexShrink={0}
                        >
                         
                          <Image src={project7} boxSize='100%' alt='Dan Abramov' />
                       
                        </Box>


                        <Box 
                          display="inline-block" 
                          marginRight="20px" 
                          width="300px" 
                          height="100%" 
                          borderRadius="15px" 
                          border="#F7C873 5px solid"
                          flexShrink={0}
                        >
                         
                          <Image src={project8} boxSize='100%' alt='Dan Abramov' />
                       
                        </Box>


                        <Box 
                          display="inline-block" 
                          marginRight="20px" 
                          width="300px" 
                          height="100%" 
                          borderRadius="15px" 
                          border="#F7C873 5px solid"
                          flexShrink={0}
                        >
                         
                          <Image src={project9} boxSize='100%' alt='Dan Abramov' />
                       
                        </Box>

                      </Box>

                    </Box>
                  </Box>

                <Text
                    // ref={descriptionRef}
                    mt={10}
                    width={{base:'90%',md:'50%'}}
                    textAlign={'center'}
                    fontSize={"20px"}
                    fontWeight={"400"}
                    fontFamily="EB Garamond"
                    color={"black"}
                    opacity={1}
                    zIndex={-1}
                >
                    Explore my top projects, each a testament to my creativity and problem-solving skills. Discover the stories behind my most impactful work
                </Text>
        </div>

        <Image src={pageNo4} alt='page no' className="pageNo"/>
      </section>

    );
}
import React, { useEffect, useRef } from "react";

// sass
import './section6.scss';

import { Heading,Link,Text ,Box, Image} from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons'
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import ReactPlayer from 'react-player'

// page no
import pageNo1 from "../../image/page_no1.png";






// sections
import Section2 from "../section_2/section2";
import Section3 from "../Section_3/section3";
import Section4 from "../section_4/section4";
import SubSection1 from "../SubSection1/SubSection1";
import Section5 from "../section_5/section5";



export default function Section1() {

  const bodyRef = useRef(null);
  const textRef = useRef(null);
  
  const videoRef = useRef(null)
  const section1Ref = useRef(null);

 
 
  const section6Ref = useRef(null);
  const backgroundRef = useRef(null);
  const section1TitleRef = useRef(null);
  const titleRef = useRef(null);
  const info1Ref = useRef(null);
  const info2Ref = useRef(null);
  const description1Ref = useRef(null);





  useEffect(()=>{
    const tl = gsap.timeline();
  tl.to(textRef.current, { zIndex: 1000, opacity: 1 ,duration: 0.5})
    .to(backgroundRef.current , {opacity:1,duration: 0.5, ease: 'power3.out'})
    .fromTo(textRef.current, { zIndex: 1000, opacity: 1.5 }, { zIndex: -1, opacity: 0.05, duration: 1, ease: 'power3.out' })
    .to(info1Ref.current,{zIndex:1000,opacity:1,duration: 1, ease: 'power3.out'})
    .to(info2Ref.current,{zIndex:1000,opacity:1,duration: 1,delay:1, ease: 'power3.out'})
    .to(backgroundRef.current , {x:-1000,zIndex:-1,duration: 1,delay:1, ease: 'power3.out'})


    const sectionOneAniTl = gsap.timeline();

    sectionOneAniTl
      .to(section1TitleRef.current, { opacity: 0, duration: 1, ease: 'power3.out' })
      .to(info1Ref.current, { opacity: 0, duration: 1, ease: 'power3.out' })
      .to(info2Ref.current, { opacity: 0, duration: 1, ease: 'power3.out' })
      .to(description1Ref.current, { y: -160, fontSize: "25px", duration: 5, ease: 'power3.out' })
      .to(backgroundRef.current, { x: 0, duration: 1, ease: 'power3.out' })
      .to(description1Ref.current, { backgroundColor: "#F8D89F", padding: "20px", borderRadius: "20px", duration: 1, ease: 'power3.out' })
      .to(videoRef.current , {display:"inline",opacity:1,y: -300, duration:2 ,delay:2, ease:"steps.out"})
      .to(description1Ref.current,{scale:0 , duration:0.5})

      let scrollTriggerInstance = ScrollTrigger.create({
        animation: sectionOneAniTl, // Assuming you have an animation timeline assigned to highlightTl
        trigger: bodyRef.current,
        start: '5px top',
        endTrigger:section1Ref.current,
        end: 'bottom top',
        pin: section1Ref.current,
        pinnedContainer:section1Ref.current,
        invalidateOnRefresh: true,
        pinSpacing: true,
        scrub: 1,
        markers: false,
      });

       // Return a cleanup function
    return () => {
      if (scrollTriggerInstance) {
        scrollTriggerInstance.kill(); // Clean up the ScrollTrigger instance
      }
    }
   
  },[1]);




  return (

    <div style={{width:"100%",height:'auto',overflow:"hidden"}} className="bodyWrapper" ref={bodyRef} >
      <section ref={section1Ref}   className="section1">
  
        <div ref={backgroundRef} className="background"></div>

        <div className="wrapper">
            <Heading ref={section1TitleRef}   as='h2' size={{ base: '2xl', md: '3xl' }} textAlign={"center"}  marginTop={{base:'100px',md:"00px"}} fontFamily="EB Garamond" color={"#6b6b6b"} fontWeight={"400"} opacity={1}>
              The Prologue
            </Heading>

            <Heading ref={info1Ref} as='h1' size={{ base: '3xl', md: '4xl' }} textAlign={"center"} marginTop={"50px"} fontFamily="Libre Baskerville" fontWeight={"400"} opacity={1}>
              Hi, I'm Praveen Maleesha
            </Heading>

            <Heading ref={info2Ref} as='h1' size={{ base: 'xl', md: '4xl' }} mt={10} fontFamily="Libre Baskerville" fontWeight={"400"} opacity={1}>
              Welcome to my story
            </Heading>

            <Text
              ref={textRef}
              fontSize={{base:'50px',md:"235px"}}
              fontWeight={"bold"}
              fontFamily="EB Garamond"
              position={"absolute"}
              top={"200px"}
              zIndex={-1}
              color={"#6b6b6b"}
              opacity={0.05}
            >
              The Prologue
            </Text>

            <Text
              ref={description1Ref}
              mt={10}
              width={{base:'90%',md:'50%'}}
              textAlign={'center'}
              fontSize={"20px"}
              fontWeight={"400"}
              fontFamily="EB Garamond"
              color={"black"}
              opacity={1}
              zIndex={-1}
            >
              <span className="highlight">Once upon a time, there was a talented and creative web developer. He can create any website you need at an affordable price. His name was Praveen Maleesha. You are warmly welcomed to his story. Let's embark on this adventure together.</span>
            </Text>

            <Box ref={videoRef} display={"none"} opacity={0} className="videoPlayer">
              <ReactPlayer  url='https://youtu.be/bmP9lo-QJRg' />
            </Box>
          
        </div>
        
        <Image src={pageNo1} alt='page no'  className="pageNo"/>
      
      </section>


      <Section2/>
      <SubSection1 text1="Creativity"  text2="Eye Catching"  text3="Clean" />
      <Section3/>
      <SubSection1 text1="Satisfaction"  text2="Quality Work"  text3="Fast Delivery"/>
      <Section4/>
      <Section5/>
     









      <section ref={section6Ref} className="section6">
        <div className="wrapper">

        <a name="contactUs"></a>

          <Heading  as='h2' size={{ base: '2xl', md: '3xl' }} textAlign={"center"}  marginTop={{base:'50px',md:"00px"}} fontFamily="EB Garamond" color={"#6b6b6b"} fontWeight={"400"}>
            Conclusion - The Next Chapter
          </Heading>

          <Heading  as='h2' size={{ base: '2xl', md: '3xl' }} textAlign={"center"}  marginTop={"50px"} fontFamily="EB Garamond" color={"#6b6b6b"} fontWeight={"400"}>
            I will write the next chapter with you
          </Heading>


                {/* <Text
                 
                   fontSize={{base:'30px',md:"180px"}}
                  fontWeight={"bold"}
                  fontFamily="EB Garamond"
                  position={"absolute"}
                  top={`${section1hight+section2hight+section3hight+section4hight+section5hight+200}px`}
                  zIndex={-1}
                  color={"#6b6b6b"}
                  opacity={0.05}
                >
                 The Next Chapter
                </Text> */}

                <Text
                  
                  mt={10}
                  width={'50%'}
                  textAlign={'center'}
                  fontSize={"20px"}
                  fontWeight={"400"}
                  fontFamily="EB Garamond"
                  color={"black"}
                  opacity={1}
                  zIndex={-1}
                  >
                  E-mail - <Link href='mailto:info@webasi.co' isExternal>
                  info@webasi.co <ExternalLinkIcon mx='2px' />
</Link> 
                </Text>

                <Text
                  
                  mt={3}
                  width={'50%'}
                  textAlign={'center'}
                  fontSize={"20px"}
                  fontWeight={"400"}
                  fontFamily="EB Garamond"
                  color={"black"}
                  opacity={1}
                  zIndex={-1}
                  >
                  Phone - <Link href='+94760668556' isExternal>
                  +94 760668556 <ExternalLinkIcon mx='2px' />
</Link> 
                </Text>

              <Box className="contactBtn" display={"flex"} flexDirection={{base:"column",md:"row"}} justifyContent={"center"} alignItems={"center"} mt="10">
                  {/* <button onClick={() => { window.open("https://www.fiverr.com/webasi", "_blank"); }}>
                    Fiverr
                  </button>

                  <button onClick={() => { window.open("https://www.linkedin.com/in/praveen-maleesha/", "_blank"); }}>
                    Linkedin
                  </button>

                  <button onClick={() => { window.open("https://www.facebook.com/profile.php?id=100087069123768", "_blank"); }}>
                    Facebook
                  </button> */}


                  <div className="button-container-1">
                      <span className="mas">Visit Now</span>
                    <button id='work' type="button" name="Hover" onClick={() => { window.open("https://www.fiverr.com/webasi", "_blank"); }} >Fiverr</button>
                  </div>

                  <div className="button-container-1">
                      <span className="mas">Explore More</span>
                    <button id='work' type="button" name="Hover" onClick={() => { window.open("https://www.linkedin.com/in/praveen-maleesha/", "_blank");}} >Linkedin</button>
                  </div>


                  <div className="button-container-1">
                      <span className="mas">Step Inside</span>
                    <button id='work' type="button" name="Hover" onClick={() => { window.open("https://www.facebook.com/profile.php?id=100064881782171", "_blank"); }} >Facebook</button>
                  </div>

              </Box>


                <Text
                 
                  mt={10}
                  width={{base:'90%',md:'50%'}}
                  textAlign={'center'}
                  fontSize={"20px"}
                  fontWeight={"400"}
                  fontFamily="EB Garamond"
                  color={"black"}
                  opacity={1}
                  zIndex={-1}
                >
                  Our journey doesn’t end here. Join me as we create the next chapter together. Let’s build something extraordinary.
              </Text>
        </div>

        {/* <Image src={pageNo1} alt='page no' /> */}
      </section>

      <footer>
              <Text
                  // ref={descriptionRef}
  
                  width={'50%'}
                  textAlign={'center'}
                  fontSize={{base:"14px",md:"18px"}}
                  fontWeight={"400"}
                  fontFamily="EB Garamond"
                  color={"black"}
                  opacity={1}
                 
                >
                  Copyright @ 2024   WEBASI.co
              </Text>
      </footer>
    </div>
  );
}

import React, { useEffect, useRef} from "react";
import { Heading,Text ,Box, SimpleGrid,Image} from '@chakra-ui/react';
import { StarIcon } from '@chakra-ui/icons'
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";


import pageNo5 from "../../image/page_no5.png";

// section 4
import world from "../../image/world.gif";


gsap.registerPlugin(ScrollTrigger);

export default function Section5() {

    const section5Ref = useRef(null);

    const  feedback1Ref = useRef(null);
    const  feedback2Ref = useRef(null);
    const  feedback3Ref = useRef(null);
    const  feedback4Ref = useRef(null);


    useEffect(()=>{
        const tl = gsap.timeline();

        tl.to(feedback1Ref.current , {backgroundColor:"#F7C873" , duration:1 , ease:"power1.in"})
        .to(feedback2Ref.current , {backgroundColor:"#F7C873" , duration:1 , ease:"power1.in"})
        .to(feedback3Ref.current , {backgroundColor:"#F7C873" , duration:1 , ease:"power1.in"})
        .to(feedback4Ref.current , {backgroundColor:"#F7C873" , duration:1 , ease:"power1.in"})
 


        let scrollTriggerInstance = ScrollTrigger.create({
            animation: tl, // Assuming you have an animation timeline assigned to highlightTl
            trigger: section5Ref.current,
            start: '50% bottom',
            end: 'bottom top',
            scrub: 1,
            markers: false,
          });
    
           // Return a cleanup function
        return () => {
          if (scrollTriggerInstance) {
            scrollTriggerInstance.kill(); // Clean up the ScrollTrigger instance
          }
        }

        

    },[])

   

    return(
        <section ref={section5Ref}  className="section5">
        <div className="wrapper">

          <Heading  as='h2' size={{ base: '2xl', md: '3xl' }} textAlign={"center"}  marginTop={{base:'50px',md:"00px"}} fontFamily="EB Garamond" color={"#6b6b6b"} fontWeight={"400"}>
            Chapter 4 - Testimonials & Feedback
          </Heading>



                <Box width={'60%'} mt="10">
                  <SimpleGrid columns={{ base: 1, md: 4 }} spacing={4}>
                    <Box gridColumn={{ base: 'span 1', md: 'span 1' }} mt="3">

                      <Box className="feedbackBox">
                        <Box className="avatar">
                            <Image boxSize='65px' marginRight={'25px'} src={"https://fiverr-res.cloudinary.com/image/upload/f_auto,q_auto,t_profile_small/v1/attachments/profile/photo/bfc9ae5a823a47b5ab1e135b3a522e88-1683828436628/a4386cc2-2459-41fd-b1fd-49e180e953a7.png"} alt='avatar' />

                            <Box>
                                <Text className="country">United States</Text>
                                <Box display={"flex"}>
                                    <StarIcon boxSize={3} />
                                    <StarIcon boxSize={3} />
                                    <StarIcon boxSize={3} />
                                    <StarIcon boxSize={3} />
                                    <StarIcon boxSize={3} />
                                </Box>
                            </Box>
                            
                        </Box>
                        <Text className="name">doxaasibey</Text>
                        <Text className="review">A helpful frontend Designer, Hardworking</Text>
                      </Box>

                      <Box className="feedbackBox" ref={feedback4Ref}>
                        <Box className="avatar">
                            <Image boxSize='65px' borderRadius={"50%"} marginRight={'25px'} src={"https://fiverr-res.cloudinary.com/image/upload/f_auto,q_auto,t_profile_small/v1/attachments/profile/photo/7525222977b2397e7237c1e13c20d7e0-1648028382313/09342c1a-9839-4883-b5df-3e97d12b471f.jpg"} alt='avatar' />
                            

                            <Box>
                                <Text className="country">Sri Lanka</Text>
                                <Box display={"flex"}>
                                    <StarIcon boxSize={3} />
                                    <StarIcon boxSize={3} />
                                    <StarIcon boxSize={3} />
                                    <StarIcon boxSize={3} />
                                    <StarIcon boxSize={3} />
                                </Box>
                            </Box>
                        </Box>
                        <Text className="name">ushandesignersl</Text>
                        <Text className="review"> I highly recommend their services for anyone looking to convert their Figma designs into HTML</Text>
                      </Box>

                    </Box>
                    
                    <Box gridColumn={{ base: 'span 1', md: 'span 1' }} mt="10">

                      <Box className="feedbackBox" ref={feedback2Ref}>
                        <Box className="avatar">
                            <Image boxSize='65px' borderRadius={"50%"} marginRight={'25px'} src={"https://fiverr-res.cloudinary.com/image/upload/f_auto,q_auto,t_profile_small/v1/attachments/profile/photo/e44b8da3b6867efefabead62700f8779-1671568542974/97b745fe-f9fa-4c5c-8b8d-27d3f4232283.jpg"} alt='avatar' />
                            
                            <Box>
                                <Text className="country">Poland</Text>
                                <Box display={"flex"}>
                                    <StarIcon boxSize={3} />
                                    <StarIcon boxSize={3} />
                                    <StarIcon boxSize={3} />
                                    <StarIcon boxSize={3} />
                                    <StarIcon boxSize={3} />
                                </Box>
                            </Box>
                        </Box>
                        <Text className="name">grammar8guru</Text>
                        <Text className="review">I recently worked with this website designer and I was extremely pleased with the results.</Text>
                      </Box>

                      <Box className="feedbackBox">
                        <Box className="avatar">
                            <Image boxSize='65px' borderRadius={"50%"} marginRight={'25px'} src={"https://fiverr-res.cloudinary.com/image/upload/f_auto,q_auto,t_profile_small/v1/attachments/profile/photo/61685cc4559dee3e73dd30eb50a5b96b-1566464898817/d879126a-7261-4610-8a94-b83a1c4a9291.jpg"} alt='avatar' />
                            

                            <Box>
                                <Text className="country">chathura masinha</Text>
                                <Box display={"flex"}>
                                    <StarIcon boxSize={3} />
                                    <StarIcon boxSize={3} />
                                    <StarIcon boxSize={3} />
                                    <StarIcon boxSize={3} />
                                    <StarIcon boxSize={3} />
                                </Box>
                            </Box>
                        </Box>
                        <Text className="name">United Kingdom</Text>
                        <Text className="review">fantastic service and talented designer.</Text>
                      </Box>

                    </Box>


                    <Box gridColumn={{ base: 'span 1', md: 'span 1' }}>

                      <Box className="feedbackBox" height={"300px"}>
                        <Box className="avatar">
                            <Image boxSize='65px' borderRadius={"50%"} marginRight={'25px'} src={"https://fiverr-res.cloudinary.com/image/upload/f_auto,q_auto,t_profile_small/v1/attachments/profile/photo/55fcd9526ea93301fd89134790d52044-1525859969045/182c65a8-eb2e-47dc-95cd-7c8945e771b6.jpg"} alt='avatar' />
                            

                            <Box>
                                <Text className="country">Malaysia</Text>
                                <Box display={"flex"}>
                                    <StarIcon boxSize={3} />
                                    <StarIcon boxSize={3} />
                                    <StarIcon boxSize={3} />
                                    <StarIcon boxSize={3} />
                                    <StarIcon boxSize={3} />
                                </Box>
                            </Box>
                        </Box>
                        <Text className="name">nikin matharaara</Text>
                        <Text className="review">It has been a pleasure working with him. He can handle a relatively large project well. Would recommend it to anyone requiring frontend work done.</Text>
                      </Box>

                      <Box className="feedbackBox" ref={feedback1Ref}>
                        <Box className="avatar">
                            <Image boxSize='65px' borderRadius={"50%"} marginRight={'25px'} src={"https://fiverr-res.cloudinary.com/image/upload/f_auto,q_auto,t_profile_small/v1/attachments/profile/photo/902643b5e2110c5b92ad6c7c9b975c3e-1023019511613509981.167583/CD3C7608-DB80-4729-A0F1-B7D34260ACCC"} alt='avatar' />
                           

                            <Box>
                                <Text className="country">Austria</Text>
                                <Box display={"flex"}>
                                    <StarIcon boxSize={3} />
                                    <StarIcon boxSize={3} />
                                    <StarIcon boxSize={3} />
                                    <StarIcon boxSize={3} />
                                    <StarIcon boxSize={3} />
                                </Box>
                            </Box>
                        </Box>
                        <Text className="name">pavelpleshakov</Text>
                        <Text className="review">A good, responsive performer!</Text>
                      </Box>

                    </Box>


                    <Box gridColumn={{ base: 'span 1', md: 'span 1' }} mt="10">

                      <Box className="feedbackBox" ref={feedback3Ref}>
                        <Box className="avatar">
                            <Image boxSize='65px' borderRadius={"50%"} marginRight={'25px'} src={"https://fiverr-res.cloudinary.com/image/upload/f_auto,q_auto,t_profile_small/v1/attachments/profile/photo/d2a6900bf5e7438ee6556218cf600571-1646294124022/a21f9c7c-e079-44e8-93d9-3e09958a1f0c.jpg"} alt='avatar' />
                            

                            <Box>
                                <Text className="country">South Africa</Text>
                                <Box display={"flex"}>
                                    <StarIcon boxSize={3} />
                                    <StarIcon boxSize={3} />
                                    <StarIcon boxSize={3} />
                                    <StarIcon boxSize={3} />
                                    <StarIcon boxSize={3} />
                                </Box>
                            </Box>
                        </Box>
                        <Text className="name">armand_marx24</Text>
                        <Text className="review">webasi was great to work with, very great at communicating my every need in this project, will work with him again. </Text>
                      </Box>

                      <Box className="feedbackBox" height={"300px"}>
                        <Box className="avatar">
                            <Image boxSize='65px' borderRadius={"50%"} marginRight={'25px'} src={"https://fiverr-res.cloudinary.com/image/upload/f_auto,q_auto,t_profile_small/v1/attachments/profile/photo/915bcd7f627abcd22fe4e375f612c0a9-924404761705772108945/JPEG_20240120_230508_68498291956554821.jpg"} alt='avatar' />
                            

                            <Box>
                            <Text className="country">India</Text>
                                <Box display={"flex"}>
                                    <StarIcon boxSize={3} />
                                    <StarIcon boxSize={3} />
                                    <StarIcon boxSize={3} />
                                    <StarIcon boxSize={3} />
                                    <StarIcon boxSize={3} />
                                </Box>
                            </Box>
                        </Box>
                        <Text className="name" >ibsbengaluru</Text>
                        <Text className="review">A helpful frontend Designer, Hardworking,Deliver the Project Within time limit. A great Experience working with him. 100% recommended.</Text>
                      </Box>

                    </Box>

                  </SimpleGrid>
                </Box>

                <Box width={"100%"} height={'40vh'} display={"flex"} flexDirection={{base:"column",md:'row'}} justifyContent={"center"} alignItems={"center"}>
                    <Image src={world} alt='world' display={{base:"none",md:"inline"}} />
                  <Text
                    // ref={descriptionRef}
                    mt={10}
                    width={{base:'90%',md:'50%'}}
                    textAlign={'center'}
                    fontSize={"20px"}
                    fontWeight={"400"}
                    fontFamily="EB Garamond"
                    color={"black"}
                    opacity={1}
                    zIndex={-1}
                  >
                    Hear from those who’ve worked with me. Client testimonials that reflect my dedication, reliability, and the quality of my work.
                  </Text>
                <Image src={world} alt='world'  display={{base:"none",md:"inline"}} />
              </Box>
          </div>

          <Image src={pageNo5} alt='page no' className="pageNo"/>
      </section>
    );
}

import React from "react";
import { Box, Image,Text, Link, List, ListItem,Tooltip } from "@chakra-ui/react";

import logo from '../../image/logo.png'


export default function Navbar(){


    return(
        <Box as="header" display="flex" alignItems="center" justifyContent="space-between" p={10} zIndex={1000} position={"absolute"}>
            <Link href="/">
                <Image src={logo} alt="logo"  width={{ base: '100px', md: '170px' }} height={{base:'30px' , md:"50px"}} /> 
            </Link>

            <nav>
            <List display="flex" alignItems="center" gap={4}>
                <ListItem>
                    <Link href="#contactUs">
                        <Tooltip label='info@webasi.co | +94 760668556' fontSize='md'>
                            <Text as='u' fontSize={{base:'sm',md:'lg'}} fontFamily="Libre Baskerville">Contact Us</Text>
                        </Tooltip>
                    </Link>
                </ListItem>
                <ListItem>
                    <Link href="#aboutUs">
                        <Text as='u' fontSize={{base:'sm',md:'lg'}} fontFamily="Libre Baskerville" >About Us</Text> 
                    </Link>
                </ListItem>
            </List>
            </nav>
        </Box>
    );
}
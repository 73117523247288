import React, { useEffect, useRef,useState } from "react";
import { Heading,Text ,Box, SimpleGrid,Image} from '@chakra-ui/react';
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

// images
import section2image from "../../image/section2image.png";
import pageNo2 from "../../image/page_no2.png";

gsap.registerPlugin(ScrollTrigger);


export default function Section2() {

    let sec2bold1 = useRef(null);
    let sec2bold2 = useRef(null);
    let sec2bold3 = useRef(null);

    const section2Ref = useRef(null);
    const sectionTwoLeftRef = useRef(null);
    const sectionTwoRightRef = useRef(null);
    const sectionTwoHeading = useRef(null);
    const background2Ref = useRef(null);

    useEffect(()=>{

        // gsap.context(() => {
          let section2AniTl = gsap.timeline();
    
          section2AniTl.to(sectionTwoHeading.current,{opacity:1,duration:3,ease:"steps.out"})
          .fromTo(sec2bold1.current,{fontWeight:400},{fontWeight:700 , duration:3 , ease:"power3.out"})
          .to(sectionTwoLeftRef.current,{transform:"rotate(-2deg)" , opacity:1,duration:10 , ease:"power3.out"})
          .fromTo(sec2bold2.current,{fontWeight:400},{fontWeight:700 , duration:3 , ease:"power3.out"})
          .to(sectionTwoRightRef.current,{transform:"rotate(5deg)" , opacity:1,duration:5 , ease:"steps.out"})
          .fromTo(sec2bold3.current,{fontWeight:400},{fontWeight:700 , duration:3 , ease:"power3.out"})
          
    
        let scrollTriggerInstance2 = ScrollTrigger.create({
            animation: section2AniTl, // Assuming you have an animation timeline assigned to highlightTl
            trigger: section2Ref.current,
            start: '80% center',
            end: '+=800 center',
            scrub: 1,
            markers: false,
            onLeave:()=>{
                gsap.to(background2Ref.current,{display:"inline",x:-1800 , duration:1,delay:1 , ease:"power2.out"})
            }
          });

          return () => {
            if (scrollTriggerInstance2) {
                scrollTriggerInstance2.kill(); // Clean up the ScrollTrigger instance
            }
          }
        // });

       
        
       
    },[1])

    return(


            <section ref={section2Ref} className="section2">
                <div ref={background2Ref} className="background2"></div>

                <div className="wrapper">
                
                <Heading ref={sectionTwoHeading}  as='h2' size={{ base: '2xl', md: '3xl' }} opacity={0}  marginTop={{base:'50px',md:"100px"}} textAlign={"center"}  fontFamily="EB Garamond" color={"#6b6b6b"} fontWeight={"400"}>
                Chapter 1 - The Beginning
                </Heading>

                <a name="aboutUs"></a>


                <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4} zIndex={2}>
                    <Box ref={sectionTwoLeftRef} display={'flex'} flexDirection={"column"} justifyContent={"center"} alignItems={"center"} opacity={0} >
                        <Text
                            // ref={descriptionRef}
                            mt={10}
                            width={{base:'90%',md:'50%'}}
                            textAlign={'center'}
                            fontSize={"20px"}
                            fontWeight={"400"}
                            fontFamily="EB Garamond"
                            color={"black"}
                            opacity={1}
                            zIndex={1}
                            >
                            From tinkering with basic code to launching my first website, this chapter delves into the spark that ignited my passion for web development. 

                            I’m Praveen Maleesha, the creative force behind <span ref={sec2bold1}>WEBASI</span>. I specialize in designing and developing modern, responsive websites that stand out.

                            With over <span ref={sec2bold2}> 2 years of experience </span>, I’ve mastered Custom HTML, CSS, JavaScript, PHP, and SQL, along with frameworks like Bootstrap, React.js, jQuery, and AJAX.

                            My WordPress expertise spans various themes and plugins, ensuring flexibility and functionality. My ultimate goal? To deliver projects that guarantee <span ref={sec2bold3}>100% client satisfaction </span>, turning visions into seamless digital realities. Join me on this exciting journey.
                        </Text>
                    </Box>
                    <Box display={"inline"} ref={sectionTwoRightRef} opacity={0}>
                        <Image src={section2image} alt='section 2 image' />
                    </Box>
                </SimpleGrid>

                
                </div>

                <Image src={pageNo2} alt='page no' className="pageNo"/>
        </section> 
    );


}
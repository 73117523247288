import Lenis from 'lenis'


import { ChakraProvider } from '@chakra-ui/react'

// component
import Navbar from './component/Navbar/Navbar';
import Section1 from './component/Section_1/Section1';
import React from 'react';

function App() {

  React.useEffect(()=>{
    if (window.innerWidth < 600) {
      const lenis = new Lenis({
        duration: 0.1,
        smooth: true,
        smoothTouch: true,
      })

      function raf(time) {
        lenis.raf(time)
        requestAnimationFrame(raf)
      }
    
      requestAnimationFrame(raf)
    } else {
      const lenis = new Lenis({
        duration: 1,
        smooth: true,
        smoothTouch: true,
      })

      function raf(time) {
        lenis.raf(time)
        requestAnimationFrame(raf)
      }
    
      requestAnimationFrame(raf)
    }
  });

  



  return (
    <ChakraProvider>
      <Navbar/>
      <Section1/>
    </ChakraProvider>
  );
}

export default App;

import React, { useEffect, useRef} from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Heading,Box} from '@chakra-ui/react';
import { SettingsIcon ,ViewIcon ,UnlockIcon} from '@chakra-ui/icons'


// Register ScrollTrigger
gsap.registerPlugin(ScrollTrigger);

export default function SubSection1 (prop){

    const textRef = useRef(null);
    const subSection1Ref = useRef(null);
  

    useEffect(() => {
        const tl = gsap.timeline();

        tl.fromTo(textRef.current,{x:-800},{x:1200 , duration:10 , ease:"power1.out"})
        .to(subSection1Ref.current,{backgroundColor:"#F7C873" , duration:1 ,ease:"power1.out" })
        .to(textRef.current,{color:"black" , duration:1 ,ease:"power1.out" })
      


        let scrollTriggerInstance3 =  ScrollTrigger.create({
            animation: tl, // Assuming you have an animation timeline assigned to highlightTl
            trigger: subSection1Ref.current,
            start: 'center top',
            end: '+=2300 bottom',
            scrub: 1,
            markers: false,
          });


          return () => {
            if (scrollTriggerInstance3) {
                scrollTriggerInstance3.kill(); // Clean up the ScrollTrigger instance
            }
          }
    });


    return(
        <Box p={10} backgroundColor={'#3E331D'} ref={subSection1Ref}>
            <Heading as='h2' size={{ base: '2xl', md: '3xl' }} noOfLines={1} fontFamily="EB Garamond" fontWeight={400} color={"#F7C873"} ref={textRef}>
              <SettingsIcon ms="5" me={5}/>  {prop.text1}  <ViewIcon ms="5" me={5} /> {prop.text2} <UnlockIcon ms="5" me={5} /> {prop.text3}
            </Heading>
        </Box>
    );
}